import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { scrollToSection } from '../../../../utils/uiEffects/scrollTo'

import TitleBanner from '../../components/TitleBanner';
import SoldProperties from './components/SoldProperties';
import ForSaleProperties from './components/ForSaleProperties';

import YANPORT from '../../../../apiPublic/ressources/yanport';

const items = ([
  { id: 1, name: 'Biens vendus dans votre secteur', component: SoldProperties },
  { id: 2, name: 'Biens en vente dans votre secteur', component: ForSaleProperties, blink: true }
])

const ComparableSales = ({ estimationFolder, comparableSales, similarSalesScreenshots }) => {
  const [ypProperties, setYpProperties] = useState([])

  useEffect(() => {
    YANPORT.getEstimationFolderProperties(estimationFolder.id).then((response) => {
      setYpProperties(response.data.similar_properties);
    });
  }, []);

  return (
    <div className='flex flex-col' data-testid='ComparableSales'>
      <TitleBanner number='03' title='Biens comparables' />


      <div className='flex flex-col'>
        {items.map(item => {
          return (
            <div key={item.id} className='flex flex-col gap-8'>
              <item.component
                estimationFolderId={estimationFolder.id}
                sale={estimationFolder.sale}
                ypProperties={ypProperties}
                dvfTransactionsIds={estimationFolder.dvf_transactions_ids}
                similarSalesScreenshots={similarSalesScreenshots}
                comparableSales={comparableSales}
              />
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default ComparableSales;

ComparableSales.propTypes = {
  estimationFolder: PropTypes.object.isRequired,
  comparableSales: PropTypes.array,
  similarSalesScreenshots: PropTypes.array
};
