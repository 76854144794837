import React from 'react';

import { Card } from 'hosman-material'
import AvatarsCounter from '../../../../../../shared/components/AvatarsCounter'

const BuyerAlertBase = () => {
  return(
    <div className='w-full md:w-max mx-auto'>
      <Card>
        <div className='flex flex-col p-5'>
          <p className='h3 text-center mx-auto mb-1'>Auprès de notre fichier client</p>

          <div className='text-center mx-auto font-medium mb-7'>
            <span className='text-ih-blue'>+ de 25 000</span> acquéreurs potentiels actifs
          </div>

          <AvatarsCounter count={25000} />
        </div>
      </Card>
    </div>
  )
};

export default BuyerAlertBase;
