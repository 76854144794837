import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CircleCheck, D_Stairs, D_House, D_BedFront, D_DrawSquare } from 'hosman-material';
import { humanizedPropertyKind, propertyArea, propertyFloor, propertyRooms } from '../../../../../utils/helpers/hosmanVars';

import { Box } from 'hosman-material';

const Characteristics = ({ property }) => {
  const { balcony, elevator, garden, parking, service_room, terrace, concierge, fireplace, cellar, bike_room, pool, garage  } = property
  const annexesSurfaces = [{name: 'Balcon', balcony}, {name: 'Ascenseur', elevator}, {name: 'Jardin', garden}, {name: 'Parking', parking}, {name: 'Garage', garage}, {name: 'Piscine', pool}, {name: 'Chambre de service', service_room}, {name: 'Terrasse', terrace}, {name: 'Conciergerie', concierge}, {name: 'Cheminée', fireplace}, {name: 'Cave', cellar}, {name: 'Local à vélo', bike_room}]
  const filteredAnnexesSurfaces = annexesSurfaces.filter(obj => !Object.values(obj).includes(null) && !Object.values(obj).includes(false) && !Object.values(obj).includes(undefined));

  const keyInformations = [
    { text: humanizedPropertyKind(property), icon: D_House },
    { text: propertyArea(property), icon: D_DrawSquare },
    { text: propertyFloor(property), icon: D_Stairs }
  ]
  if (property.kind !== 'parking_spot') keyInformations.splice(1, 0, { text: propertyRooms(property), icon: D_BedFront });

  return (
    <div className='flex flex-col md:flex-row gap-3 text-sm' data-testid='Characteristics'>
      <div className='md:w-4/6'>
        <p className='font-semibold mb-4'>Informations clés</p>

        <ul className='flex flex-col gap-4'>
          {
            keyInformations.map((information, index) => {
              return(
                <li key={index} className='flex items-center gap-2 text-ih-indigo' data-testid='keyInfo'>
                  <div className='w-min'>
                    <Box border='border border-ih-blue-50' size='w-8 h-8 rounded-xl'>
                      <FontAwesomeIcon icon={information.icon} className='text-ih-blue' />
                    </Box>
                  </div>

                  <div>{information.text}</div>
                </li>
              )
            })
          }
        </ul>
      </div>

      <div className='md:w-4/6'>
        {filteredAnnexesSurfaces.length > 0 &&
          <>
            <p className='font-semibold mb-4'>Prestations</p>

            <ul className='flex flex-col gap-3'>
              {
                filteredAnnexesSurfaces.map((annexeSurface, index) => {
                  return(
                    <li key={index} className='flex items-center gap-2 text-ih-indigo' data-testid='annexeSurface'>
                      <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green w-5 h-5' />

                      <div>{annexeSurface.name}</div>
                    </li>
                  )
                })
              }
            </ul>
          </>
        }
      </div>
    </div>
  );
}

export default Characteristics;

// PropTypes
Characteristics.propTypes = {
  property: PropTypes.object.isRequired
}
