import React from 'react';

export { agencyFees as calculateAgencyFees } from '../../../../shared/helpers/agencyFeesHelper';

export const NO_SPECIAL_CHARACTER_REGEX = /^[^\/\\^_&*%+=$<>@§°()\[\]{}£`?!|,;.:€#]+$/;

export const definePremium = (parisRegion, price) => {
  return ((!parisRegion && price > process.env.PREMIUM_START_VALUE_REGIONS) || price > process.env.PREMIUM_START_VALUE_PARIS)
}

export const pricePerMeter = (price, property) => {
  let area = (['house', 'mansion'].includes(property.kind) && property.living_area) ? property.living_area : property.carrez_area
  if (!area && property.area) area = property.area
  if (!area) return 'Surface manquante'

  return `${Math.floor(price/area).toLocaleString('fr-FR')} €/m²`
}

export const ponderatedPricePerMeter = (price, property) => {
  let area = property.ponderated_area;
  if (!area) return;

  return `${Math.floor(price/area).toLocaleString('fr-FR')} €/m²`
}

export const humanizedPropertyKind = (property) => {
  switch (property.kind) {
    case 'apartment':
      return 'Appartement'
    case 'duplex':
      return 'Duplex'
    case 'loft':
      return 'Loft'
    case 'house':
      return 'Maison'
    case 'mansion':
      return 'Hôtel particulier'
    case 'parking_spot':
      return 'Parking'
    default:
      return 'Appartement'
  }
}

export const propertyArea = (property) => {
  const surfaceArea = (type) => {
    if (!property.surface_area || property.surface_area === (type === 'apartment' ? property.carrez_area : property.living_area)) return '';

    return `, ${property.surface_area}m² au sol`
  }

  switch (property.kind) {
    case 'apartment':
    case 'duplex':
    case 'loft':
      return `${property.carrez_area}m² carrez${surfaceArea('apartment')}`
      break;
    case 'house':
    case 'mansion':
      return `${property.living_area}m²${surfaceArea('house')}`
      break;
    default:
      return `${property.surface_area}m²`
  }
}

const apartmentKindFloorText = (floor, total_floor, elevator) => {
  if (floor === 0) {
    return <p>Rez-de-chaussée</p>
  } else {
    return(
      <>
        <p>{`${floor}${floor === 1 ? 'er' : 'e'} étage / ${total_floor}`}</p>
        <p>{`(${elevator ? 'Avec' : 'Sans'} ascenseur)`}</p>
      </>
    )
  }
}

export const propertyFloor = (property) => {
  switch (property.kind) {
    case 'apartment':
    case 'duplex':
    case 'loft':
      return apartmentKindFloorText(property.floor, property.total_floor, property.elevator)
      break;
    case 'house':
    case 'mansion':
      if (property.total_floor === 0) {
        return 'Plain-pied'
      } else {
        return `${property.total_floor} étage${property.total_floor > 1 ? 's' : ''}`
      }
      break;
    default:
      return property.floor ? `Niveau ${property.floor}` : 'n/a'
  }
}

export const propertyRooms = (property) => {
  return `${property.room_number} pièce${property.room_number > 1 ? 's' : ''}, ${property.bedroom_number} chambre${property.bedroom_number > 1 ? 's' : ''}`
}

export const greeting = (user) => {
  return `${user.civility === 'mrs' ? 'Madame' : 'Monsieur'} ${user.last_name}`;
}
