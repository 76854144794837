import client from '../client';

const YANPORT = {
  getProperty(yanportId) {
    return client.get(`/yanport_properties/${yanportId}`)
  },
  
  getEstimationFolderProperties(estimationFolderId) {
    return client.get(`/estimation_folders/${estimationFolderId}/yanport_similar_properties`)
  },
}

export default YANPORT;
