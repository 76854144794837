import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CircleCheck } from 'hosman-material';
import { calculateAgencyFees, definePremium } from '../../../../../utils/helpers/hosmanVars';

import { Card, HosmanLogo, PremiumLogo } from 'hosman-material';

const classicItems = [
  'Un expert immobilier local dédié tout au long de votre vente',
  'Des photos professionnelles (10), un plan 2D, et une visite virtuelle 3D',
  'Publication de votre annonce auprès de notre base acheteurs et + 40 portails immobiliers',
  "Votre espace en ligne où vous suivrez en temps réel votre projet de vente (visites, offres d'achats...)",
  "L'accès à notre réseau de partenaires notaires et l'accompagnement administratif jusqu'à la vente définitive"
]

const premiumItems = [
  'Un spécialiste Premium dédié tout au long de votre vente',
  'Des photos professionnelles (16), un plan 2D, et une visite virtuelle 3D',
  'Publication de votre annonce auprès de notre base acheteurs et + 40 portails immobiliers et Premium',
  "Votre espace en ligne où vous suivrez en temps réel votre projet de vente (visites, offres d'achats...)",
  "L'accès à notre réseau de partenaires notaires et l'accompagnement administratif jusqu'à la vente définitive"
]

const HosmanOffer = ({ sale }) => {
  const agencyFees = sale.mandate_signed
    ? sale.agency_fees
    : calculateAgencyFees(
        sale.region.name_raw === 'Paris',
        sale.estimation_medium,
        false
      );

  const classicFees = sale.estimation_medium < 150_000 ? sale.estimation_medium*0.08 : sale.estimation_medium*0.047
  const premium = definePremium(sale.region.name_raw === 'Paris', sale.estimation_medium)

  const sellerSavings = Math.floor(classicFees - agencyFees)

  const calculateNoExclusivityAddFees = () => {
    if (sale.estimation_medium <= process.env.PREMIUM_START_VALUE_REGIONS) { return '+ 2000 €' }
    else if (sale.region.name_raw === 'Paris' && sale.estimation_medium <= process.env.PREMIUM_START_VALUE_PARIS) { return '+ 4000 €'}
    else { return '+ 0,5%' }
  }

  return (
    <div className='flex flex-col gap-6 text-ih-indigo px-8' data-testid='HosmanOffer'>
      <Card>
        <div className='flex flex-col gap-10 py-5 md:py-10'>
          <div className='flex flex-col gap-4'>
            <p className='h2 text-center'>L'offre Hosman <span className='text-ih-blue'>pour vous </span></p>
            {premium &&
              <div className='mx-auto w-max'>
                <div className='flex items-center gap-3'>
                  <HosmanLogo />
                  <PremiumLogo />
                </div>
              </div>
            }
          </div>
          <div className='mx-auto'>
            <div className='grid grid-cols-1 md:grid-cols-12 gap-10 md:divide-x-2 px-8'>
              <div className='md:col-span-7'>
                <div className='flex flex-col gap-4'>
                  <div className='flex flex-col md:flex-row gap-3'>
                    <p className='h3 text-center md:text-left'>Votre accompagnement Hosman</p>
                    <div className='mx-auto'>
                      {premium && <PremiumLogo width={100} />}
                    </div>
                  </div>

                  {(premium ? premiumItems : classicItems).map((item, index) => {
                    return (
                      <div key={index} className='flex items-center gap-3'>
                        <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' size='lg' />
                        <p>{item}</p>
                      </div>
                    )
                  })}

                  <div className='vesta-margin-t-20'>
                    <div className='flex items-center gap-3'>
                      <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' size='lg' />
                      <p>Option mandat simple {calculateNoExclusivityAddFees()}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='md:col-span-5 flex flex-col gap-6 text-center px-4'>
                <div className='flex flex-col gap-1'>
                  <p className='h2'>Votre prix</p>
                  <p className='h1 text-ih-blue italic'>{premium ? '2,5%' : `${agencyFees.toLocaleString()} €`}</p>
                </div>
                <p className='font-semibold'>Votre net vendeur</p>

                <div className='flex justify-center gap-4 align-bottom'>
                  <div className='flex flex-col gap-3 items-center'>
                    <p className='text-ih-blue'>{Math.floor(sale.estimation_medium - agencyFees).toLocaleString()} €</p>
                    <div className='w-24 h-24 bg-ih-blue rounded-xl'></div>
                    <p>Hosman</p>
                  </div>
                  <div className='flex flex-col gap-3 items-center justify-end'>
                    <p className='text-ih-indigo-20'>{Math.floor(sale.estimation_medium - classicFees).toLocaleString()} €</p>
                    <div className='w-24 h-20 bg-ih-indigo-20 rounded-xl'></div>
                    <p>Agence classique</p>
                  </div>
                </div>

                
                { sellerSavings > 0 &&
                  <p className='h3 w-3/4 mx-auto'>Avec Hosman, économisez <span className='text-ih-blue'>{sellerSavings.toLocaleString()} €</span> sur votre net vendeur</p>
                }
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default HosmanOffer;

// PropTypes
HosmanOffer.propTypes = {
  sale: PropTypes.object.isRequired
}
