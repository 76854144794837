import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { humanizedPropertyKind } from '../../../shared/utils/helpers/hosmanVars';
import { monthAndYear } from '../../../shared/utils/formattedDates';

import { S_Xmark, S_CalendarDays } from 'hosman-material';

const DvfPropertyCard = ({ property, transaction, onClick, onRemove, selected }) => {
  return (
    <div key={transaction.id} onClick={() => onClick ? onClick(transaction.id) : null} className={`flex flex-col justify-between border ${onClick ? 'cursor-pointer' : ''} ${selected ? 'border-ih-green' : 'border-ih-purple-40'} rounded-2xl w-72 shadow-ih-2`}>
      <div className={`flex flex-col gap-2 p-3 h-full rounded-t-2xl ${selected ? 'bg-ih-green-10' : 'bg-white'} relative`}>
        {onRemove &&
          <div className='absolute -top-2 -right-2 cursor-pointer bg-ih-red-20 hover:bg-ih-red-40 rounded-full flex justify-center items-center w-6 h-6'>
            <FontAwesomeIcon icon={S_Xmark} className='text-ih-red font-bold' onClick={() => onRemove(transaction.id)} />
          </div>
        }

        <p className='h4 text-ih-indigo'>{transaction.address.formatted.replace(', France', '').replace('Arrondissement', '')}</p>
        <div className='flex items-center text-xs text-ih-indigo divide-x divide-ih-purple-30'>
          <p className='pr-2'>{humanizedPropertyKind(property)}</p>
          <p className='px-2'>{transaction.features.geometry.surface}m²</p>
          <p className='px-2'>{transaction.features.geometry.roomCount} pièce{transaction.features.geometry.roomCount > 1 ? 's' : ''}</p>
        </div>

        <div className='flex items-center gap-2'>
          <FontAwesomeIcon icon={S_CalendarDays} size='sm' className='text-ih-blue' />
          <p className='text-xs text-ih-indigo-50 mt-1'>Vendu en {monthAndYear(transaction.marketing.marketingEndDate)}</p>
        </div>
      </div>

      <div className='p-3 bg-ih-purple-10 text-center rounded-b-2xl'>
        <p className='h4 text-ih-blue'>{transaction.marketing.price.toLocaleString()} €</p>
        <p className='text-xs text-ih-indigo'>{Math.round(transaction.marketing.priceM2).toLocaleString()} €/m²</p>
      </div>
    </div>
  )
}

export default DvfPropertyCard;

// PropTypes
DvfPropertyCard.propTypes = {
  property: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  selected: PropTypes.bool
};
