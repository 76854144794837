import React from 'react';
import PropTypes from 'prop-types';

const InventoryScreenshots = ({ inventoryScreenshots }) => {
  if (inventoryScreenshots.length < 1) return null;

  return (
    <div className='flex flex-col gap-3' data-testid='InventoryScreenshots'>
      {inventoryScreenshots.map((screenshot) => {
        return (
          <img key={screenshot.id} src={screenshot.screenshot_url.replace('.png', '.webp')} className='md:w-2/3 mx-auto rounded' />
        )
      })}

      <p className='h4 text-center'>Source : Meilleurs Agents - Prix affiché net vendeur</p>
    </div>
  );
}

export default InventoryScreenshots;

InventoryScreenshots.propTypes = {
  similarSalesScreenshots: PropTypes.array
};
