import React from 'react';
import PropTypes from 'prop-types';

import DvfTransactions from './components/DvfTransactions';
import InventoryScreenshots from './components/InventoryScreenshots';
import PatrimScreenshots from './components/PatrimScreenshots';
import HosmanProperties from '../../components/HosmanProperties';
import { Separator, HosmanLogo } from 'hosman-material';

const SoldProperties = ({ sale, similarSalesScreenshots, comparableSales, dvfTransactionsIds }) => {
  const hosmanProperties = comparableSales.filter((cSale) => ['awaiting_sale_agreement', 'awaiting_notarial_deed', 'sold'].includes(cSale.sale_state))
  const inventoryScreenshots = similarSalesScreenshots.filter(screenshot => screenshot.source === 'inventory')
  const patrimScreenshots = similarSalesScreenshots.filter(screenshot => screenshot.source === 'patrim')

  return (
    <div className='flex flex-col gap-10 text-ih-indigo bg-hosman-gradient-1 py-10' data-testid='SoldProperties'>
      <div className='text-center'>
        <p className='h2'>Découvrez les biens vendus <span className='text-ih-blue'>dans votre secteur</span></p>
      </div>

      <div className='flex flex-col gap-5'>
        <div className='text-center'>
          <p className='h3 text-xl fsize-22'>Vendus par d'autres agences</p>
        </div>

        <div className='flex flex-col gap-12'>
          {dvfTransactionsIds.length > 0 &&
            <>
              <DvfTransactions sale={sale} dvfTransactionsIds={dvfTransactionsIds} />
              <Separator margins='my-2' />
            </>
          }
          <InventoryScreenshots inventoryScreenshots={inventoryScreenshots} />
          {inventoryScreenshots.length > 0 && <Separator margins='my-2' />}
          <PatrimScreenshots patrimScreenshots={patrimScreenshots} />
          {patrimScreenshots.length > 0 && <Separator margins='my-2' />}
        </div>
      </div>

      {hosmanProperties.length > 0 &&
        <div className='flex flex-col gap-5'>
          <div className='h3 text-xl flex items-center mx-auto fsize-22'>
              <div>Vendus avec </div>
              <HosmanLogo width={100}/>
            </div>
          <HosmanProperties
            sale={sale}
            comparableSales={hosmanProperties}
          />
        </div>
      }
    </div>
  );
}

export default SoldProperties;

SoldProperties.propTypes = {
  sale: PropTypes.object.isRequired,
  comparableSales: PropTypes.array,
  similarSalesScreenshots: PropTypes.array,
};
