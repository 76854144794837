import React from 'react';

export const nextSteps = [
  {
    number: 1,
    imageUrl: 'https://res.cloudinary.com/vesta-home/image/upload/v1689082087/assets/Napoleon/Images/EstimationFolder/NextSteps/step1.webp',
    title: <p className='h3'>Récupération des <span className='text-ih-blue'>documents obligatoires</span> pour la commercialisation</p>,
    content:
      <ul className='list-disc list-inside text-sm'>
        <li>Titre de propriété</li>
        <li>Diagnostics</li>
        <li>Règlement de copropriété</li>
        <li>3 derniers PVs d'Assemblée Générale</li>
      </ul>
  },
  {
    number: 2,
    imageUrl: 'https://res.cloudinary.com/vesta-home/image/upload/v1689149769/assets/Napoleon/Images/EstimationFolder/NextSteps/step2.webp',
    title: <p className='h3'>Mise en valeur et multi-diffusion de votre annonce sur <span className='text-ih-blue'>+ de 40 portails immobiliers</span> professionnels</p>,
    content: <p className='text-sm'>Pour chacun de nos biens, nous réalisons des photos professionnelles, un plan et une visite 3D. L'annonce est ensuite diffusée sur tous les portails immobiliers et à notre base d'acheteurs qui comprend +30 000 personnes en recherche active.</p>
  },
  {
    number: 3,
    imageUrl: 'https://res.cloudinary.com/vesta-home/image/upload/v1689149768/assets/Napoleon/Images/EstimationFolder/NextSteps/step3.webp',
    title: <p className='h3'><span className='text-ih-blue'>Prise en charge des visites </span> et suivi en temps réel</p>,
    content: <p className='text-sm'>Votre expert immobilier dédié s'occupe de tout, vous tient informé et vous suivez en temps réel chaque étape depuis votre espace client en ligne (Suivi des visites planifiées, comptes-rendus de visite, réception des offres d'achat...)</p>
  },
  {
    number: 4,
    imageUrl: 'https://res.cloudinary.com/vesta-home/image/upload/v1689149768/assets/Napoleon/Images/EstimationFolder/NextSteps/step4.webp',
    title: <p className='h3'>Gestion de la négociation et <span className='text-ih-blue'>sélection du meilleur profil</span></p>,
    content: <p className='text-sm'>Votre expert vous aide à choisir le meilleur dossier, et nos outils digitaux permettent d'accélérer les délais de vente (signature électronique du mandat, prise de visites en ligne, envoi automatique des documents au notaire...)</p>
  },
  {
    number: 5,
    imageUrl: 'https://res.cloudinary.com/vesta-home/image/upload/v1689149769/assets/Napoleon/Images/EstimationFolder/NextSteps/step5.webp',
    title: <p className='h3'>C'est vendu et au <span className='text-ih-blue'>prix net vendeur</span> le plus élevé !</p>,
    content: <p className='text-sm'>Notre tarif juste, 2 fois moins cher en moyenne qu'en agence traditionnelle, vous permet d'obtenir un prix net vendeur plus intéressant.</p>
  }
]
