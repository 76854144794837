import React from 'react';
import PropTypes from 'prop-types';

const PatrimScreenshots = ({ patrimScreenshots }) => {
  if (patrimScreenshots.length < 1) return null;

  return (
    <div className='flex flex-col gap-3 px-6' data-testid='PatrimScreenshots'>
      {patrimScreenshots.map((screenshot) => {
        return (
          <img key={screenshot.id} src={screenshot.screenshot_url.replace('.png', '.webp')} className='mx-auto' />
        )
      })}

      <p className='h4 text-center'>Source: Base de données des valeurs foncières - Prix affiché net vendeur</p>
    </div>
  );
}

export default PatrimScreenshots;

PatrimScreenshots.propTypes = {
  similarSalesScreenshots: PropTypes.array
};
