import React , { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DvfPropertyCard from '../../../../../../../../shared/components/Property/DvfPropertyCard';

import YANPORT from '../../../../../../../../shared/apiPublic/ressources/yanport';

const DvfTransactions = ({ sale, dvfTransactionsIds }) => {
  const [dvfTransactions, setDvfTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      const promises = dvfTransactionsIds.map(id => fetchDvfTransaction(id));

      await Promise.all(promises);
    };

    fetchTransactions();

    return function cleanup() {
      setDvfTransactions([])
    };
  }, []);

  const fetchDvfTransaction = async(id) => {
    const response = await YANPORT.getProperty(id)

    setDvfTransactions((prev) => {
      const updatedTransactions = [...prev, response.data];

      const uniqueTransactions = Array.from(
        new Map(updatedTransactions.map(item => [item.id, item])).values()
      );

      return uniqueTransactions;
    });
  }

  return (
    <div className='flex flex-col gap-6' data-testid='DvfTransactions'>
      <div className='flex flex-wrap justify-center gap-5'>
        {dvfTransactions.map((transaction) => {
          return (
            <DvfPropertyCard
              key={transaction.id}
              property={sale.property}
              transaction={transaction}
            />
          )
        })}
      </div>

      <p className='h4 text-center'>Source : DVF (Demandes de valeurs foncières) - Prix affiché net vendeur</p>
    </div>
  );
}

export default DvfTransactions;

DvfTransactions.propTypes = {
  sale: PropTypes.object,
  dvfTransactionsIds: PropTypes.array
};
