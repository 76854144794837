import React from 'react';
import PropTypes from 'prop-types';
import { longDate } from '../../../utils/formattedDates';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CircleCheck, S_Phone, S_Envelope, D_ArrowDownToBracket } from 'hosman-material';

import { Label, Box, RoundedTopImage } from 'hosman-material';

const Introduction = ({ estimationFolder }) => {
  const subtitles = [
    <p>Hosman, la référence immobilière avec <span className='font-semibold'>+6 biens vendus par jour</span></p>,
    <p>Un tarif fixe, en moyenne <span className='font-semibold'>2x moins cher</span> qu'une agence traditionnelle</p>,
    <p>Une estimation précise et fiable basée sur de la <span className='font-semibold'>donnée vérifiée</span></p>
  ]

  const expertUser = estimationFolder.sale.expert.user

  return (
    <div className='flex flex-col gap-6' data-testid='Introduction'>
      <div className='md:flex items-center justify-around text-ih-indigo bg-hosman-gradient-1 rounded-xl py-10'>
        <div className='flex flex-col gap-8'>
          <div className='text-center md:text-left'>
            <div className='flex flex-col md:flex-row items-baseline justify-between gap-3'>
              <p className='h1'>Votre estimation</p>
              {estimationFolder.sent_at &&
                <p className='text-sm mb-8'>Le {longDate({ date: estimationFolder.sent_at, capitalized: true })} {new Date(estimationFolder.sent_at).getFullYear()}</p>
              }
            </div>
            <p className='h2 text-ih-blue'>{estimationFolder.sale.property.address.replace(', France', '')}</p>
          </div>

          <div className='md:hidden'>
            <RoundedTopImage src={estimationFolder.property_picture_url} />
          </div>

          <div className='flex flex-col gap-3'>
            {subtitles.map((subtitle, index) => {
              return (
                <div key={index} className='flex items-center gap-2'>
                  <FontAwesomeIcon icon={S_CircleCheck} className='text-ih-green' size='lg' />
                  {subtitle}
                </div>
              )
            })}
          </div>

          {estimationFolder.estimation_file_url &&
            <div className='hidden md:flex items-center gap-2'>
              <FontAwesomeIcon icon={D_ArrowDownToBracket} className='text-ih-blue' />
              <a href={estimationFolder.estimation_file_url} className='link text-ih-blue underline' download target='_blank'>Télécharger un PDF récapitulatif du dossier</a>
            </div>
          }
        </div>

        <div className='hidden md:block w-80'>
          <RoundedTopImage src={estimationFolder.property_picture_url} />
        </div>
      </div>

      <div className='flex flex-col gap-10 text-ih-indigo md:w-3/4 mx-auto pb-20'>
        <p className='h2 text-center'>Quelques mots de votre expert <span className='text-ih-blue'>{expertUser.first_name}</span></p>

        <div className='flex flex-col md:flex-row gap-12 items-center justify-center'>
          <div className='w-full'>
            <div className='flex justify-center md:justify-end'>
              <div className='w-2/3 relative'>
                <RoundedTopImage src={expertUser.full_profile_pic_url} aspect='aspect-[3/4]' />
                <div className='absolute -bottom-2 left-1/2 taransform -translate-x-1/2'><Label color='bg-ih-blue'>{expertUser.first_name}</Label></div>
              </div>
            </div>
          </div>

          <div className='w-full flex flex-col gap-5'>
            <p>L'estimation de votre bien est un <span className='font-semibold'>acte décisif</span> qui déterminera la réussite de votre projet immobilier. Ainsi, votre bien <span className='font-semibold'>doit être estimé au juste prix.</span></p>
            <p>Je vous accompagne ensuite <span className='font-semibold'>tout au long de votre projet de vente</span> de la publication de l'annonce jusqu'à la signature de l'acte authentique.</p>
            <div>
              <p>Bonne lecture à vous,</p>
              <p className='font-semibold'>{expertUser.first_name}</p>
            </div>

            <div className='flex flex-col gap-3 font-semibold'>
              <a href={`tel:${expertUser.phone_number}`} className='group flex items-center gap-3'>
                <Box bgColor='bg-ih-purple-20 group-hover:bg-ih-purple-30'>
                  <FontAwesomeIcon icon={S_Phone} data-testid='icon-box' className='text-ih-indigo' />
                </Box>
                <p>{expertUser.phone_number.replace(/.{2}/g, '$& ')}</p>
              </a>
              <a href={`mailto:${expertUser.email}`} className='group flex items-center gap-3'>
                <Box bgColor='bg-ih-blue-20 group-hover:bg-ih-blue-30'>
                  <FontAwesomeIcon icon={S_Envelope} data-testid='icon-box' className='text-ih-blue' />
                </Box>
                <p>{expertUser.email}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;

Introduction.propTypes = {
  estimationFolder: PropTypes.object.isRequired,
};
